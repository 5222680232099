.game-container {
  background-color: #4cd964;
  width: 100%;
  height: 700px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.card-hand-container {
  display: flex;
}
