/* 
 Introduction
*/

.introduction-content {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  padding: 30px;
  transition: all 0.5s ease-in;
}

.introduction-content-dark {
  color: white;
  background-color: black;
}

.intro-image-container {
  width: 100%;
  max-width: 400px;
  padding-bottom: 20px;
}

.intro-image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
  padding-bottom: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
}

.intro-text-block {
  max-width: 680px;
  text-align: center;
}

/* 
 Experience
*/

.experience-section {
  background-color: #f2f2f2;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: all 0.5s ease-in;
}

.experience-section-dark {
  background-color: #171717;
  color: white;
}

.experience-content {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.experience-text-container {
  max-width: 500px;
  width: 100%;
  padding: 20px;
}

.experience-image-container {
  width: 300px;
  height: 300px;
  min-height: 300px;
  min-width: 300px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.experience-header {
  font-size: 24px;
  font-weight: 500;
}

.experience-dates {
  color: #8f8f8f;
}

.experience-dates-dark {
  color: white;
}

.experience-body {
  color: #666;
}

.experience-body-dark {
  color: white;
}

.ag-logo {
  background-color: rgb(13, 64, 242);
}

.ucf-logo-container {
  background-color: black;
}

.ucf-logo {
  height: 97%;
}

.experience-image {
  display: block;
  max-width: 100%;
}

/* 
 My Skills
*/
.skills-section {
  padding: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.5s ease-in;
}

.skills-section-dark {
  background-color: black;
  color: white;
}

.skill-container {
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f2f2f2;
  max-width: 350px;
  min-height: 400px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  transition: all 0.5s ease-in;
}

.skill-container-dark {
  background-color: #171717;
  color: white;
}

.skill-content {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.skill-header {
  font-size: 22px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}

.skill-body {
  font-size: 17px;
}

/* 
 Other
*/

.landing-title {
  text-align: center;
  font-size: 32px;
  font-weight: 500;
}

.wrap-reverse {
  flex-wrap: wrap-reverse;
}
