.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  transition: all 0.5s ease-in;
}

.wrapper-dark {
  background-color: black;
}

.projects-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1100px;
}

.project-container {
  width: 100%;
  height: 100%;
  max-height: 300px;
  max-width: 300px;
  margin: 20px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  -webkit-overflow: hidden;
}

.project-title {
  font-weight: bold;
  font-size: large;
  justify-self: center;
  text-align: center;
}

.project-title-dark {
  color: white;
}

.hover-content {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(100%);
  transition: transform 0.2s ease-out;
}

.hover-content-dark {
  background-color: #131313;
}

.projects-grid > div:hover .hover-content {
  transform: translateY(0);
}

.project-image {
  height: 100%;
}

.mapifi-additions {
  display: flex;
  background-image: linear-gradient(
    46deg,
    #f464a7 2.48%,
    #baaad4 48.63%,
    #8cd8f8 92.98%
  );
}

.smaller-image {
  width: 80%;
}

.image-shadow {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
}
