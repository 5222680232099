.ays-container {
  transition: all 0.5s ease-in;
}

.ays-container-dark {
  background-color: black;
  color: white;
}

.ays-header {
  font-size: 32px;
  font-weight: 500;
  padding: 20px;
}

.ays-body-image {
  display: flex;
  gap: 20px;
  padding: 20px;
  align-items: center;
  justify-content: center;
}

.ays-image {
  max-width: 300px;
}

.ays-screenshot {
  width: 280px;
}

.ays-screenshots {
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.tool-widgets {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.tool-widget {
  padding: 20px;
  max-width: 400px;
  min-width: 150px;
  width: 100%;
  background-color: #f2f2f2;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  display: flex;
  border-radius: 8px;
  gap: 14px;
}

.tool-widget-dark {
  background-color: #131313;
}

/* SMALL SCREEN */
@media (max-width: 600px) {
  .ays-body-image {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}
