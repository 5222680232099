.navbar-name {
  color: white;
  font-weight: bolder;
}

.nav-container {
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  height: 100%;
}

.navbar {
  height: 100px;
  background-color: black;
  position: relative;
  z-index: 1;
}

.menu-icon {
  display: none;
}

.nav-elements {
  display: flex;
  align-items: center;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #7f7f7f;
  text-decoration: none;
  font-weight: bolder;
}

.nav-elements ul a.active {
  color: white;
  position: relative;
}

.navbar ul a:hover {
  color: white;
  transition: color 0.75s ease;
}

.entry-button {
  background-color: white;
  padding: 10px 20px 10px 20px;
  border-radius: 6px;
  cursor: pointer;
}

.entry-button:hover {
  background-color: #f2f2f2;
  transition: color 0.75s ease;
}

/* SMALL SCREEN WHEN MENU SHOWS */
@media (max-width: 670px) {
  .nav-elements {
    display: block;
    position: absolute;
    right: 0;
    top: 60px;
    background-color: black;
    width: 0px;
    height: 200px;
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }

  .menu-icon {
    display: block;
  }
}
