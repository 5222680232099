.auth-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Slightly transparent black */
  backdrop-filter: blur(10px); /* Apply the blur effect */
  -webkit-backdrop-filter: blur(10px);
  z-index: 10;
}

.auth-modal-container {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  max-width: 650px;
  width: 100%;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 26px;
  position: fixed;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;
  z-index: 1000;
  transition: all 0.5s ease-in;
}

.auth-modal-container-dark {
  background-color: black;
  color: white;
}

.auth-modal-header {
  font-size: 32px;
  font-weight: 500;
  justify-content: space-between;
  display: flex;
  width: 100%;
}

.auth-modal-textfield {
  display: flex;
  width: 100%;
  border: none;
  padding: 24px;
  background-color: #f2f2f2;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: bolder;
  resize: none;
  transition: all 0.5s ease;
}

.auth-modal-textfield-dark {
  background-color: #252525;
  color: white;
}

.auth-modal-textfield:focus {
  outline: none !important;
  background-color: #e5e5e5;
  transition: background-color 0.5s ease;
}

.auth-modal-textfield-dark:focus {
  outline: none !important;
  background-color: #252525;
  transition: background-color 0.5s ease;
}
