.contact-container {
  padding: 50px 30px;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transition: all 0.5s ease-in;
}

.contact-container-dark {
  background-color: black;
  color: white;
}

.contact-textfield {
  display: flex;
  width: 100%;
  border: none;
  padding: 24px;
  background-color: #f2f2f2;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: bolder;
  resize: none;
  transition: all 0.5s ease;
}

.contact-textfield-dark {
  background-color: #252525;
  color: white;
}

.contact-textfield:focus {
  outline: none !important;
  background-color: #e5e5e5;
  transition: background-color 0.5s ease;
}

.contact-textfield-dark:focus {
  outline: none !important;
  background-color: #252525;
  transition: background-color 0.5s ease;
}

.large-textfield {
  height: 170px;
}

.contact-card {
  padding: 30px;
  max-width: 850px;
  width: 100%;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  text-align: left;
  border-radius: 26px;
  font-weight: bolder;
  box-sizing: border-box;
  transition: background-color 0.5s ease-in;
}

.contact-card-dark {
  background-color: #131313;
}

.send-email-button {
  padding: 20px;
  height: 30px;
  background-color: black;
  color: white;
  align-content: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: background-color 0.3s ease-in;
}

.send-email-button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.send-email-button-dark {
  background-color: #373737;
}

.send-email-button-dark:hover {
  border: 2px solid transparent;
  background-color: #494949;
  color: white;
}
