.footer-container {
  padding: 50px;
  justify-content: space-evenly;
  display: flex;
  background-color: #f2f2f2;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  transition: all 0.5s ease;
}

.footer-container-dark {
  background-color: #131313;
  color: white;
}

.footer-content {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 200px;
  padding-top: 30px;
}

.footer-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}
